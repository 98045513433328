<template>
  <b-card-code no-body>
    <div class="d-flex ml-2 mb-2">
      <b-button to="devices/add" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success">
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Adicionar novo</span>
      </b-button>
    </div>
    <div v-if="
      items.filter((data) => data.groupId == $store.state.active_group.id) ==
      ''
    ">
      <h4 style="margin: 1rem; margin-bottom: 2rem; margin-left: 1.5rem">
        Nenhum dispositivo encontrado
      </h4>
    </div>
    <b-overlay opacity="0.5" :show="devices_overlay" spinner-variant="success" spinner-type="grow" spinner-small
      rounded="sm">
      <b-table v-if="
        items.filter((data) => data.groupId == $store.state.active_group.id) !=
        ''
      " striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage" :items="
  items.filter((data) => data.groupId == $store.state.active_group.id)
" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(name)="data">
          <div class="demo-inline-spacing">
            <b-avatar variant="light-dark" style="margin-right: 0.5rem; margin-top: 0rem">
              <feather-icon :icon="possible_icons[data.item.icon].icon" size="20" />
            </b-avatar>
            <div style="margin-top: 0rem">
              <h5 class="mb-0">
                {{ data.item.name }}
              </h5>
              <small class="text-muted"> #{{ data.item.id }} </small>
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <div @click="selectDevice(data.item.id)">
            <b-button variant="flat-primary" class="btn-icon" v-b-tooltip.hover.v-primary title="Ver dispositivo"
              to="/devices/view-device">
              <feather-icon icon="EyeIcon" />
            </b-button>

            <b-button variant="flat-success" class="btn-icon" v-b-tooltip.hover.v-success title="Editar dispositivo"
              to="/devices/edit-device">
              <feather-icon icon="EditIcon" />
            </b-button>

            <!-- <b-button variant="flat-info" class="btn-icon" v-b-tooltip.hover.v-info title="Dados do dispositivo"
              v-b-modal.modal-delete to="/devices/view-datas">
              <feather-icon icon="BoxIcon" />
            </b-button> -->

            <b-button @click="getDeviceInfo(data.item)" variant="flat-danger" class="btn-icon"
              v-b-tooltip.hover.v-danger title="Apagar dispositivo" v-b-modal.modal-delete>
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>

        <template #cell(createdAt)="data">
          {{ data.item.createdAt.split("T", 2)[0] }}
        </template>

        <template #cell(token)="data">
          <b-input-group>
            <b-form-input :value="data.value" />
            <b-input-group-append>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" class="btn-icon"
                v-b-tooltip.hover.v-secondary title="Copiar" @click="doCopy(data.value)">
                <feather-icon icon="CopyIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
      </b-table>
    </b-overlay>

    <b-modal ref="modal-delete" id="modal-delete" centered title="Deletar dispositivo" hide-footer>
      <b-card-text>
        <div style="text-align: center">
          <feather-icon style="color: rgb(249, 175, 0)" icon="AlertCircleIcon" size="100" />
          <h3 style="margin-top: 0.5rem">Apagar o dispositivo {{ device_delete_data.name }} ?</h3>
          <p>Esta ação não pode ser desfeita</p>
          <div style="margin-top: 2rem" @click="hideModal">
            <b-button style="margin-right: 1.5rem" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success"
              @click="confirmDelete">
              Delete isso!
            </b-button>

            <b-button variant="outline-danger"> Cancelar </b-button>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filtro -->
        <b-form-group v-if="
          items.filter(
            (data) => data.groupId == $store.state.active_group.id
          ) != ''
        " label="Filtro" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Procure por alguma coisa" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>

      <!-- pagination -->
      <div v-if="
        items.filter(
          (data) => data.groupId == $store.state.active_group.id
        ) != ''
      ">
        <b-pagination v-model="currentPage" :total-rows="
          this.items.filter(
            (data) => data.groupId == $store.state.active_group.id
          ).length
        " :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BOverlay,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBTooltip,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import store from "@/store/index";
import api from '../service/api.js'

export default {
  components: {
    BOverlay,
    BCardText,
    VBTooltip,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    let self = this;
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }

    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }
    
    self.items = await api.get_devices();
    self.devices_overlay = false;
  },

  data() {
    return {
      devices_overlay: true,
      device_delete_data: [],
      Swal: require("sweetalert2"),
      perPage: 6,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        { key: "name", label: "Nome", sortable: true },

        { key: "status", label: "Estado", sortable: true },

        { key: "token", label: "Key API", sortable: false },

        { key: "createdAt", label: "Criado em", sortable: true },

        { key: "actions", label: "Ações", sortable: false },
      ],
      items: [],
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
          selected: "flat-success",
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
          selected: "flat-darken-2",
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
          selected: "flat-darken-2",
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
          selected: "flat-darken-2",
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
          selected: "flat-darken-2",
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
          selected: "flat-darken-2",
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
          selected: "flat-darken-2",
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
          selected: "flat-darken-2",
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
          selected: "flat-darken-2",
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
          selected: "flat-darken-2",
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
          selected: "flat-darken-2",
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
          selected: "flat-darken-2",
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
          selected: "flat-darken-2",
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
          selected: "flat-darken-2",
        },
      ],
      status: [
        {
          0: "Inativo",
          1: "Ativo",
        },
        {
          0: "light-danger",
          1: "light-info",
        },
      ],
    };
  },
  methods: {
    getDeviceInfo(data) {
      var self = this;
      self.device_delete_data = [];
      self.device_delete_data = data;
    },
    doCopy(value) {
      navigator.clipboard.writeText(value);
      var self = this;
      let title = "Notificação";
      let icon = "BellIcon";
      let text = "Token copiado!";
      let variant = "success";
      self.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    hideModal() {
      this.$refs["modal-delete"].hide();
    },
    onFiltered(filteredItems) {
      this.currentPage = 1;
    },
    selectDevice(value) {
      store.state.selected_device = value;
    },
    async confirmDelete() {
      var self = this;

      self.devices_overlay = true;

      let request_device = "id=" + store.state.selected_device;
      let selected_device = await api.find_devices(request_device);
      if (selected_device.length > 0) {
        let request_token = "token=" + selected_device[0].token;
        let group_request = "&groupId=" + selected_device[0].groupId;

        let response = await api.deleteDevice(request_token, group_request);
        if (response == "success") {
          Swal.fire(
            "Deletado!",
            "Dispositivo deletado com sucesso!",
            "success"
          );
          self.items = await api.get_devices();
        } else {
          Swal.fire("Erro!", "Algo deu errado no meio do caminho", "warning");
        }
      } else {
        Swal.fire("Algo deu errado!", "Tente ir para outra página, e tentar novamente", "warning");
      }
      self.devices_overlay = false;
    },
  },
};
</script>

<style>
.page-item.active .page-link {
  background-color: #ff4804;
}
</style>

